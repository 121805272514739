import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "path": "/developer/javascript-closures",
  "date": "2015-08-27",
  "title": "JAVASCRIPT & CLOSURES",
  "author": "admin",
  "tags": ["development", "javascript"],
  "featuredImage": "feature.jpg",
  "excerpt": "A closure is an inner function that has access to the outer (enclosing) function’s variables—scope chain."
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`The closure has three scope chains:`}</p>
    <ul>
      <li parentName="ul">{`It has access to its `}<inlineCode parentName="li">{`own scope`}</inlineCode>{` (variables defined between its curly brackets)`}</li>
      <li parentName="ul">{`It has access to the `}<inlineCode parentName="li">{`outer function`}</inlineCode>{`'s variables`}</li>
      <li parentName="ul">{`and it has access to the `}<inlineCode parentName="li">{`global`}</inlineCode>{` variables`}</li>
    </ul>
    <h2>{`Access to outer variables`}</h2>
    <p>{`What if a variable is accessed, but it isn’t local? Like here:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`var bar = 5;

function foo() {
  alert(bars);
}
`}</code></pre>
    <p>{`In this case, the interpreter finds the variable in the outer lexical scope.`}</p>
    <p>{`The process consists of two steps:`}</p>
    <ul>
      <li parentName="ul">{`When a function `}<strong parentName="li">{`foo`}</strong>{` is created, it is not created in an empty space.
There is a current lexical scope. In the case above, it’s `}<inlineCode parentName="li">{`window`}</inlineCode>{` (bar is undefined at the time of function creation).
`}</li>
    </ul>
    <p>{`When a function is created, it gets a hidden property, named `}<inlineCode parentName="p">{`[[Scope]]`}</inlineCode>{`, which references current lexicl scope.`}</p>
    <ul>
      <li parentName="ul">{`Later, when the function runs, it creates it’s own lexical scope and links it with `}<inlineCode parentName="li">{`[[Scope]]`}</inlineCode>{`.
So when a variable is not found in the local lexical scope, it is searched outside:`}</li>
    </ul>
    <p>{`If a variable is read, but can not be found anywhere, the error is generated.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`function foo() {
  alert(bar) // reading bar gives error, no bar
}
`}</code></pre>
    <p>{`Certain language constructs block the error, for example `}<strong parentName="p">{`typeof bar`}</strong>{` works if there is no bar (and returns undefined), but that’s an exception.`}</p>
    <p>{`If a variable is set, but not found anywhere, then it is created in the outmost lexical scope, which is window.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`function foo() {
  bar = 5; // writing bar puts it into window
}
`}</code></pre>
    <h2>{`Nested functions`}</h2>
    <p>{`Functions can be nested one inside another, forming a chain of lexical scopes which can also be called a `}<inlineCode parentName="p">{`scope chain`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`var bar = 1;

function foo() {

  function baz() {
    alert(bar);
  }

  return baz;
}

var func = foo()l
func(); // 1
`}</code></pre>
    <p>{`Lexical scope form a chain (from inside out):`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`// LexicalScope = window = {bar:1, foo: function}
var bar = 1;

function foo() {
  // LexicalScope = {baz:function}

  function baz() {
    // LexicalScope = {}
    alert(bar);
  }

  return baz;
}
`}</code></pre>
    <p>{`So, function `}<strong parentName="p">{`baz`}</strong>{` has access to `}<strong parentName="p">{`baz, foo, and bar`}</strong>{`.`}</p>
    <p>{`Nested functions may continue to live after the outer function has finished:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`function User(name) {
  this.say = function(phrase) {
    alert(name + ' says: ' + phrase);
  }
}

var user = new User('John');
`}</code></pre>
    <p>{`Note, the `}<strong parentName="p">{`this`}</strong>{` context is not related to scopes and variables. It does not participate here.As we see, `}<strong parentName="p">{`this.say`}</strong>{` is a property in the user object, so it continues to live after User completed.`}</p>
    <p>{`And if you remember, when `}<strong parentName="p">{`this.say`}</strong>{` is created, it (as every function) gets an internal reference to `}<strong parentName="p">{`this.say.[`}{`[Scope]`}{`]`}</strong>{` to current lexical scope. So, the lexical scope of the current User execution stays in memory. All variables of User also are its properties, so they are also carefully kept, not junked as usually.`}</p>
    <p>{`The whole point is to ensure that if the inner function wants to access an outer variable in the future, it can do so.`}</p>
    <h2>{`Mutability of Lexical Scope`}</h2>
    <p>{`Several functions may share same outer LexicalEnvironment. In this case, they can modify it’s properties.`}</p>
    <p>{`In the example below, this.fixName changes name, which is used by this.say:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`function User(name) {

  this.fixName = function() {
    name = 'Mr.' + name.toUpperCase();
  }

  this.say = function(phrase) {
    alert(name + ' says: ' + phrase);
  }

}

var user = new User('John'); // (1)
user.fixName(); // (2)
user.say("I'm alive!"); // Mr.JOHN says: I'm alive!
`}</code></pre>
    <p>{`Here `}<strong parentName="p">{`user.fixName.[`}{`[Scope]`}{`]`}</strong>{` and `}<strong parentName="p">{`user.say.[`}{`[Scope]`}{`]`}</strong>{` reference same lexical scope, which corresponds to new User run.`}</p>
    <p>{`From (1) to (2), the `}<strong parentName="p">{`LexicalScope.name`}</strong>{` is updated, so both functions see the variable change.`}</p>
    <h2>{`[`}{`[Scope]`}{`] for new Function`}</h2>
    <p>{`There is an exception to general scope binding rule. When you create a function using new Function, it’s [`}{`[Scope]`}{`] points to window, not to current lexical scope.`}</p>
    <p>{`The following example demonstrates how a function, created with new Function ignores local variable a and outputs the global variable.`}</p>
    <p>{`The regular behavior:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`window.a = 1;
function getFunc() {
  var a = 2;

  var func = function() { alert(a) }

  return func;
}

getFunc()() // 2, from LexicalEnvironemnt of getFunc
And now the function, created by :

window.a = 1
function getFunc() {
  var a = 2

  var func = new Function('', 'alert(a)')
  return func
}

getFunc()() // 1, from window
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      